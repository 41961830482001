.main {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.ads_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  padding-left: 10px;
  gap: 20px;
}
