.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.table_body_row {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  background-color: white;
}

.table_body_row h1 {
  font-size: 1rem;
  font-weight: 400;
}

.table_body_row p:first-child {
  font-size: 0.8rem;
}

.img_parts {
  height: 100px;
  aspect-ratio: 9/5;
  /* background: black; */
  margin-left: 40px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
}
