.container {
  height: fit-content;
  width: 100%;
}

.table_head {
  padding-left: 50px;
}

.table_head_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-overflow: ellipsis;
}

.table_body_row {
  background: white;
  margin-bottom: 5px;
  min-height: 50px;
  height: fit-content;
  padding-left: 50px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.table_body_row p {
  height: 100%;
}
